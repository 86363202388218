var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mr-3" },
    [
      _vm._l(_vm.todoList, function (item, index) {
        return _c(
          "v-card",
          { key: index, attrs: { elevation: "0" } },
          [
            _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c("v-checkbox", {
                  staticClass: "my-2 me-auto",
                  attrs: { "hide-details": "" },
                  on: { change: _vm.completeTodoTask },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "font-weight-medium grey--text text--darken-4 text-subtitle-2",
                              },
                              [_vm._v(" " + _vm._s(item.name) + " ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: item.is_completed,
                    callback: function ($$v) {
                      _vm.$set(item, "is_completed", $$v)
                    },
                    expression: "item.is_completed",
                  },
                }),
                _c(
                  "v-btn",
                  {
                    staticClass: "ma-1",
                    attrs: { icon: "", "aria-label": "delete" },
                    on: {
                      click: function ($event) {
                        return _vm.deleteToDoClicked(item)
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("mdi-delete-outline")])],
                  1
                ),
              ],
              1
            ),
            _c("v-icon", { staticClass: "ml-1", attrs: { size: "18px" } }, [
              _vm._v("mdi-calendar-blank-outline"),
            ]),
            _c(
              "span",
              {
                staticClass: "text--darken-2 caption",
                class: _vm.$vuetify.breakpoint.mdAndUp ? "mx-2" : "",
              },
              [
                _vm._v(_vm._s(_vm.$t("created")) + ": "),
                _c("b", [_vm._v(_vm._s(item.created_at))]),
              ]
            ),
            _c("v-divider", { staticClass: "my-2" }),
          ],
          1
        )
      }),
      !_vm.addTodo
        ? _c(
            "div",
            { staticClass: "mt-2" },
            [
              _c(
                "v-btn",
                {
                  attrs: { id: "add-todo", elevation: "0" },
                  on: { click: _vm.addTodoClicked },
                },
                [
                  _c("v-icon", { staticClass: "mr-2" }, [
                    _vm._v("mdi-plus-circle"),
                  ]),
                  _vm._v(" " + _vm._s(_vm.$t("addToDo"))),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.addTodo
        ? _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  clearable: "",
                  autofocus: "",
                  dense: "",
                  "background-color": "#F5F5F5",
                  "clear-icon": "mdi-close-circle",
                  placeholder: "Task Name",
                },
                on: {
                  blur: _vm.addTodoTask,
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.addTodoTask.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.toDoName,
                  callback: function ($$v) {
                    _vm.toDoName = $$v
                  },
                  expression: "toDoName",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.deleteDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "420",
                "max-height": "400",
                persistent: "",
              },
              model: {
                value: _vm.deleteDialog,
                callback: function ($$v) {
                  _vm.deleteDialog = $$v
                },
                expression: "deleteDialog",
              },
            },
            [
              _c(
                "v-card",
                { staticClass: "overflow-hidden" },
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-0 ml-4" },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "col-10 px-0 grey--text text--darken-4 text-h6 text-truncate",
                        },
                        [_vm._v(_vm._s(_vm.$t("deleteTask")))]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "col-2 my-auto",
                          attrs: { "aria-label": "cross", icon: "" },
                          on: { click: _vm.closeDeleteDialog },
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-row", [_c("v-divider")], 1),
                  _c("v-row", { staticClass: "ma-0 ml-4 mb-10 pt-4" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "grey--text text--darken-4 text-subtitle-1 font-weight-regular",
                      },
                      [_vm._v(_vm._s(_vm.$t("deleteTaskMessage")))]
                    ),
                  ]),
                  _c("v-row", { staticClass: "mb-2" }, [_c("v-divider")], 1),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-none rounded-lg elevation-0",
                          attrs: {
                            color: "primary",
                            outlined: "",
                            depressed: "",
                            width: "90",
                          },
                          on: { click: _vm.closeDeleteDialog },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "primary text-none rounded-lg elevation-0",
                          attrs: { width: "90", depressed: "" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteToDo()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("delete")) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }