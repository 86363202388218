<template>
  <div class="mr-3">
    <v-card elevation="0" v-for="(item, index) in todoList" :key="index">
      <div class="d-flex">
        <v-checkbox
          hide-details
          v-model="item.is_completed"
          @change="completeTodoTask"
          class="my-2 me-auto"
        >
          <template v-slot:label>
            <div
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
            >
              {{ item.name }}
            </div>
          </template>
        </v-checkbox>
        <v-btn
          icon
          aria-label="delete"
          class="ma-1"
          @click="deleteToDoClicked(item)"
        >
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </div>
      <v-icon class="ml-1" size="18px">mdi-calendar-blank-outline</v-icon>
      <span
        class="text--darken-2 caption"
        :class="$vuetify.breakpoint.mdAndUp ? 'mx-2' : ''"
        >{{ $t("created") }}: <b>{{ item.created_at }}</b></span
      >
      <v-divider class="my-2"></v-divider>
    </v-card>
    <div v-if="!addTodo" class="mt-2">
      <v-btn id="add-todo" elevation="0" @click="addTodoClicked"
        ><v-icon class="mr-2">mdi-plus-circle</v-icon>
        {{ $t("addToDo") }}</v-btn
      >
    </div>
    <div v-if="addTodo" class="mt-3">
      <v-text-field
        v-model="toDoName"
        outlined
        clearable
        autofocus
        dense
        background-color="#F5F5F5"
        clear-icon="mdi-close-circle"
        @blur="addTodoTask"
        @keyup.enter="addTodoTask"
        placeholder="Task Name"
      ></v-text-field>
    </div>
    <v-dialog
      v-if="deleteDialog"
      v-model="deleteDialog"
      max-width="420"
      max-height="400"
      persistent
    >
      <v-card class="overflow-hidden">
        <v-row class="ma-0 ml-4">
          <span
            class="col-10 px-0 grey--text text--darken-4 text-h6 text-truncate"
            >{{ $t("deleteTask") }}</span
          >
          <v-btn
            class="col-2 my-auto"
            aria-label="cross"
            icon
            @click="closeDeleteDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-row>
          <v-divider></v-divider>
        </v-row>
        <v-row class="ma-0 ml-4 mb-10 pt-4">
          <span
            class="grey--text text--darken-4 text-subtitle-1 font-weight-regular"
            >{{ $t("deleteTaskMessage") }}</span
          >
        </v-row>
        <v-row class="mb-2">
          <v-divider></v-divider>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            outlined
            class="text-none rounded-lg elevation-0"
            depressed
            width="90"
            @click="closeDeleteDialog"
          >
            {{ $t("cancel") }}
          </v-btn>

          <v-btn
            class="primary text-none rounded-lg elevation-0"
            width="90"
            depressed
            @click="deleteToDo()"
          >
            {{ $t("delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
export default {
  name: "AddTask",
  mixins: [validationMixin],
  validations() {
    return {};
  },
  data() {
    return {
      toDoName: "",
      addTodo: false,
      todoList: [],
      deleteDialog: false,
      deleteItem: {},
    };
  },
  props: {
    editTaskDetails: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    closeDeleteDialog() {
      this.deleteItem = {};
      this.deleteDialog = false;
    },
    addTodoClicked() {
      this.addTodo = !this.addTodo;
    },
    addToDoTask() {
      this.addTodo = !this.addTodo;
    },
    async addTodoTask() {
      this.addTodo = !this.addTodo;
      if (!this.toDoName) return;
      let newDate = new Date();
      let day = newDate.getDate();
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      let createdAt = `${month}/${day}/${year}`;
      this.todoList.push({
        name: this.toDoName,
        status: "Created",
        created_at: createdAt,
        updated_at: "",
      });
      this.toDoName = null;
      const taskDetails = {
        todo_items: this.todoList,
        task_id: this.editTaskDetails.task_id,
      };
      const updatetaskResp = await this.$store.dispatch(
        "updateProjectTask",
        taskDetails,
      );
      if (updatetaskResp.status === "error") {
        console.log("error in task update");
      }
      this.editTaskDetails.todo_items = this.todoList;
    },
    deleteToDoClicked(item) {
      this.deleteItem = item;
      this.deleteDialog = true;
    },
    deleteToDo() {
      let tempToDoList = this.todoList.filter((val) => {
        return !(val.name === this.deleteItem.name);
      });
      this.todoList = tempToDoList;
      this.completeTodoTask();
      this.deleteItem = {};
      this.deleteDialog = false;
    },
    async completeTodoTask() {
      const taskDetails = {
        todo_items: this.todoList,
        task_id: this.editTaskDetails.task_id,
      };
      const updatetaskResp = await this.$store.dispatch(
        "updateProjectTask",
        taskDetails,
      );
      if (updatetaskResp.status === "error") {
        console.log("error in task update");
      }
      this.editTaskDetails.todo_items = this.todoList;
    },
  },
  async created() {
    this.todoList = this.editTaskDetails.todo_items
      ? this.editTaskDetails.todo_items
      : [];
  },
};
</script>
<style lang="scss">
#add-todo {
  padding: 0 !important;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  color: #0073d1;
  text-transform: capitalize;
}
</style>
